.login {
    background-color: $body-bg;
    color: $light-color; 
    width: 450px;
    @extend .p-0;

    .login-header {
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .login-title {
        text-align: center;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1;

        label {
            background-color: $secondary-color;
        }
    }

    .login-subtitle {
        text-align: center;
        font-size: 1.5rem;
    }

    form {
        input[type=text], input[type=password] {
            border: 0;
            font-size: 1.25rem;

            &:focus {
                box-shadow: none;
            }
        }

        input[type=checkbox] {
            accent-color: $light-color;
        }

        .input-container {
            background-color: #fff;
            padding: 1% 3%;
            border-bottom: 5px #171D8D solid;
        }

        .terms-and-conditions {
            margin: 5% 5%;
        }
    }

    // .btn {
    //     color: $light-color;
    //     font-size: 1.5rem;
    //     font-weight: 600;
    //     padding: 0 5%;
    // }

    .login-language {
        margin: 5% 5%;
        .select-language {
            max-width: 33%;
        }
    }

    .change-password {
        margin: 5% 5%;
    }

    .login-logos {
        padding-top: 2%;
        .primary-logo {
            max-width: 45px;
        }
        .secondary-logo {
            max-width: 30px;
        }
    }
}